import React from 'react';
import styles from './Home.module.css';
import Typewriter from "typewriter-effect";
import ourVission from '../MediaFolder/ourVission.jpg'
import ourMission from '../MediaFolder/ourMission.webp'
import service1 from '../MediaFolder/Fire-Alarm-Systems.jpg'
import service2 from '../MediaFolder/Security worker using walkie talkie to communicate with team in dark security room.webp'
import service3 from '../MediaFolder/azienda-servizi-ingegneria-antincendio-sicurezza-metodo-lavoro.jpg'
import service4 from '../MediaFolder/servv4.jpg'
import service5 from '../MediaFolder/servv5.jpg'
import service6 from '../MediaFolder/servv6.jpg'
import p1 from '../MediaFolder/p1.png'
import p2 from '../MediaFolder/p2.jpg'
import p3 from '../MediaFolder/p3.png'
import p4 from '../MediaFolder/p4.png'
import p5 from '../MediaFolder/p5.png'
import p6 from '../MediaFolder/p6.png'
import p7 from '../MediaFolder/p7.jpg'
import p8 from '../MediaFolder/png-transparent-coca-cola-logo-coca-cola-logo-company-business-cola-company-text-photography.png'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'




export default function Home() {

    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };
    
    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];
    
        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
    
        this.el.innerHTML = '<span className={`${styles.wrap}`}>'+this.txt+'</span>';
    
        var that = this;
        var delta = 200 - Math.random() * 100;
    
        if (this.isDeleting) { delta /= 2; }
    
        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
        }
    
        setTimeout(function() {
        that.tick();
        }, delta);
    };
    
    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
    };

    useEffect(() => {
        AOS.init({duration: 1500})
      
      }, [])

  return (
   <>

   {/* first section */}
    <header className='vh-100'>
        {/* <img src={bgImg} alt="" className='w-100 h-100'/> */}
        <div className={`${styles.layerpageone} vh-100 d-flex justify-content-center align-items-center text-white text-center`}>

    <div className="w-100 text-center ">

        <h1 className="text-white fs-1" >
        <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .typeString("EPS Engineering For Protection Systems")
                            .pauseFor(500)
                            .start();
                        }} 
                    />
        </h1>
    </div>

    <div className="write text-white " >
        <h3 className="text-center"></h3>
    </div>
    </div>

    </header>

      {/* Seconed section */}
        <section className='container my-5 '>
            <h2 className='text-center fs-3 fw-bolder'>Who We Are </h2>
            <div className="longline"></div>
             <div className="shortline mb-2"></div>              
            <p className='text-center mt-5'>The Engineering Company for Protection Systems is considered one of the leading national companies, which was founded in <span className={`${styles.mainColor} fw-bolder fs-5`}>2011 </span>. Since its launch in that year, the company has taken rapid steps to develop its distinguished work in the field of contracting. The company's main tasks are the constant pursuit of providing all services to all customers, whether companies or individuals, in a distinctive manner</p>
        </section>

      {/* Third section */}
        <section className='my-5'>
        <div className={`container mt-3 pt-3`} >
        <div className={`${styles.bgCard} p-5`}>

        <h2 className='text-center fs-3 fw-bolder'>
        Services
    
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
    
<p className='text-center my-5'> We are the authorized distributors of the major American and English brands where automatic alarms, automatic extinguishing devices and cameras. We have the highest work group and skill, whether experienced and efficient engineers or technicians to cover and handle any work at any time. We have a team for quick maintenance at any time, 24 hours. </p>
    <div className="row my-3">

 

        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service1} className={`${styles.imgHover} ${styles.imgSize} w-100 card-img-top `} alt="Automatic fire alarm and extinguishing systems"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>

        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Automatic warning and recognition system</h2>
            <div className='mt-1'>Supply and installation of all automatic fire alarm systems</div>
            <div className='mt-1'>Supply and installation of all automatic fire fighting systems</div>
            <p className='mt-2'>Turn off the water , Extinguish carbon dioxide , Fire extinguishers , Extinguishers</p>
        </div>
        </div>
        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service3} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="General contracting works and regular works"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>
        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Water network and fire networks​</h2>
            <div className="mt-1">Supply and installation of all water pumps and fire pumps</div>
            <div className="mt-1">Maintenance of water and fire networks</div>
            <div className="mt-1">Maintenance of water and fire pumps</div>
        </div>
        </div>
        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service2} className={`${styles.imgHover} ${styles.imgSize} w-100 card-img-top `} alt="Electromechanical systems"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>

        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Automated theft alarm systems​</h2>
            <div className='mt-1'>Supply and installation of all theft systems , this project is all about building an anti theft alarm. It is possible to enhance this project with many features like adding a GSM module to send SMS alerts to specified mobile numbers</div>
        </div>
        </div>
        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service4} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Public supplies"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>

        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Control and audio systems​</h2>
            <div className='mt-1'>Supply and installation of all automatic surveillance systems (cameras)</div>
            <div className="mt-1">Supply and installation of all audio systems</div>
        </div>
        </div>
        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service5} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Public supplies"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>

        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Electromechanical business systems​</h2>
            <div className='mt-1'>Supply and installation of all electromechanical works</div>
            <div className="mt-1">Central air conditioning , smoke exhaust , ventilation</div>
        </div>
        </div>
        <div className="card col-md-4 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden} imgAnime`}>
        <img src={service6} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Public supplies"/>
        <div className='redLayer text-white fs-1 d-flex align-items-center justify-content-center'> <p>EPS</p> </div>

        </div>
        <div className={`${styles.divBG} card-body `}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Chemical treatment systems​</h2>
            <div className='mt-1'>Supply and coating of fire resistance materials</div>
            <div className="mt-1">Wood , curtains , carpeting , electrical cables and iron</div>
        </div>
        </div>
  

        
   

    
    


    </div>
            

    
    </div>
    </div> 
        </section>

        {/* Fourth section */}
                <section >
        <div className="container my-4">
        <div className={`${styles.bgCard} p-4`}>
        <div className={`row align-items-center`}>
            <div className="col-md-6 ">
            <div className="p-1">
                    <h3 className='text-center fs-3 fw-bolder '>
                    Our Vision
                    <div className="longline"></div>
                    <div className="shortline mb-3"></div>
                    </h3>
                <p className='text-center'>We promise to protect people and their environment from devastating fire and includes: fire risk analysis; Mitigating fire damage through proper design, construction, arrangement, and use of buildings, materials, structures, industrial processes, and transportation systems; Design, installation and maintenance of fire detection and suppression systems. Our company's purpose is to inspire and guide the direction of systems engineering across diverse stakeholder communities. This vision will continue to evolve based on stakeholder input and ongoing collaboration.</p>
            </div>
        
            </div>
            <div className="col-md-6 my-1">
            <div>
                    <img src={ourVission} alt="" className={`${styles.imgbrdr} w-100`}/>
            </div>
            </div>
        </div>
        <div className={`row align-items-center`}>

        <div className="col-md-6 my-1">
            <div>
                    <img src={ourMission} alt="" className={`${styles.imgbrdr} w-100`}/>
            </div>
            </div>

            <div className="col-md-6 ">
            <div className="p-1">
                    <h3 className='text-center fs-3 fw-bolder '>
                    Our Mission
                    <div className="longline"></div>
                    <div className="shortline mb-3"></div>
                    </h3>
                <p className='text-center'>Our company ensures high efficiency by adhering to various rules and regulations and increasing the overall productivity of your building with maximum protection. Engineers also ensure highly customized services according to the needs and requirements of a specific site, but we also keep a close eye on project budget goals. Our company provides clear decision-making criteria and our performance will always meet expectations
        </p>
            </div>
        
            </div>

        </div>
        </div>
        
        </div>


        </section>
      {/* Fourth section */}
        <section className=' container my-5'>
            <h2 className='text-center fs-3 fw-bolder my-5'>Our Partners   
                <div className="longline"></div>
                <div className="shortline mb-2"></div>
            </h2>
            <div className="row text-center" data-aos="zoom-out">
                <div className="col-md-3">
                    <img src={p1} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p2} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p3} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p4} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p5} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p6} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p7} alt="" className='w-50'/>
                </div>
                <div className="col-md-3">
                    <img src={p8} alt="" className='w-50'/>
                </div>
            </div>

        </section>

       
       {/* Fifth section */}
        <section className='container'>
        <div className="row">
            <h2 className='text-center'>Location</h2>
        <div className="col-md-12 text-center">
        <div className="longline"></div>
        <div className="shortline mb-5"></div>
        <iframe title='eps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.551827538199!2d31.215907400000003!3d30.049713000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584127ef1b2177%3A0xd22cde5e357ee44!2s35%20El-Fardous%2C%20Al%20Agouzah%2C%20Agouza%2C%20Giza%20Governorate%203753550!5e0!3m2!1sen!2seg!4v1714025982999!5m2!1sen!2seg" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


      

        </div>
        </div>
        </section>



   </>
  )
}
