import React from 'react'
import styles from './OurWork.module.css'
import proj1 from '../MediaFolder/projectsMedia/vi.jpeg'
import proj2 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.02.23.jpeg'
import proj3 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.05.jpeg'
import proj4 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.06 (1).jpeg'
import proj5 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.06 (2).jpeg'
import proj6 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.06 (3).jpeg'
import proj7 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.06 (4).jpeg'
import proj8 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.06.06.jpeg'
import proj9 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.02.23 (1).jpeg'
import proj10 from '../MediaFolder/projectsMedia/1-1.jpeg'
import proj11 from '../MediaFolder/projectsMedia/1-2.jpeg'
import proj12 from '../MediaFolder/projectsMedia/1-3.jpeg'
import proj13 from '../MediaFolder/projectsMedia/1-4.jpeg'
import proj14 from '../MediaFolder/projectsMedia/1-5.jpeg'
import proj15 from '../MediaFolder/projectsMedia/1-6.jpeg'



export default function Proj1() {
  return (
   <>
   <div className="container my-5">
    <div className="row">

    <p className='text-center fw-bold my-5 fs-5 pt-5'>Balloon Theater Tank Project and Fire Pump Room, Artistic House for Folk and Performing Arts  </p>
    <div className="col-md-4 my-1 text-center">
            <img src={proj1} alt="" className={`${styles.proImgSize}`}/>
        </div>
    <div className="col-md-4 my-1 text-center">
            <img src={proj15} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj10} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj11} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj12} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj13} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj14} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj2} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj3} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj4} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj5} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj6} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj7} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj8} alt="" className={`${styles.proImgSize}`}/>
        </div>
        <div className="col-md-4 my-1 text-center">
            <img src={proj9} alt="" className={`${styles.proImgSize}`}/>
        </div>


    </div>
   </div>
   </>
  )
}
