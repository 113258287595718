import './App.css';
import {Routes , Route} from 'react-router-dom';
import ContactUs from './Components/ContactUsFolder/ContactUs';
import Footer from './Components/FooterFolder/Footer';
import Home from './Components/HomePageFolder/Home';
import NavBar from './Components/NavBarFolder/NavBar';
import AboutUs from './Components/AboutUsFolder/AboutUs';
import OurWorks from './Components/OurWorksFolder/OurWorks';
import Proj1 from './Components/OurWorksFolder/Proj1';
import Proj2 from './Components/OurWorksFolder/Proj2';
import Proj3 from './Components/OurWorksFolder/Proj3';
import Proj4 from './Components/OurWorksFolder/Proj4';
import Proj5 from './Components/OurWorksFolder/Proj5';
import Proj6 from './Components/OurWorksFolder/Proj6';
import Proj7 from './Components/OurWorksFolder/Proj7';
import Proj8 from './Components/OurWorksFolder/Proj8';
import Proj9 from './Components/OurWorksFolder/Proj9';
import AllProjects from './Components/OurWorksFolder/AllProjects';
import Proj10 from './Components/OurWorksFolder/Proj10';
import Proj11 from './Components/OurWorksFolder/Proj11';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
<>

<ScrollToTop>

<NavBar />
  <Routes> 
            <Route path='*' element={ <Home />}/>
            <Route path='home' element={ <Home />}/>
            <Route path='contactUs' element={ <ContactUs />}/>
            <Route path='aboutUs' element={ <AboutUs />}/>
            <Route path='ourWorks1' element={ <OurWorks />}/>
            <Route path='proj1' element={ <Proj1 />}/>
            <Route path='proj2' element={ <Proj2 />}/>
            <Route path='proj3' element={ <Proj3 />}/>
            <Route path='proj4' element={ <Proj4 />}/>
            <Route path='proj5' element={ <Proj5 />}/>
            <Route path='proj6' element={ <Proj6 />}/>
            <Route path='proj7' element={ <Proj7 />}/>
            <Route path='proj8' element={ <Proj8 />}/>
            <Route path='proj9' element={ <Proj9 />}/>
            <Route path='proj10' element={ <Proj10 />}/>
            <Route path='proj11' element={ <Proj11 />}/>
            <Route path='ourWorks2' element={ <AllProjects />}/>

           
  </Routes>
<Footer />

</ScrollToTop>
</>
  );
}

export default App;
