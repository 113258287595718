import React from 'react'
import styles from './Footer.module.css'
import logo from '../MediaFolder/eps.png'


export default function Footer() {
  return (
  <>
     <footer className={`${styles.footerBG} pt-1 mt-5`}>
        <div className="container-fluid">
            <div className="row align-items-center text-center">
                  
                    <div className='col-md-6 leftSideFooter'>
                        <div>
                          <img src={logo} alt="" className='w-25' />
                        </div>
                        <div className='socialMedia my-3'>
                        <i className="fa-brands fa-facebook me-4 fs-2 text-white"></i>
                        <i className="fa-brands fa-instagram mx-4 fs-2 text-white"></i>
                        <i className="fa-brands fa-linkedin mx-4 fs-2 text-white"></i>
                        </div>
                        
                    </div>
                    <div className="col-md-6 rightSideFooter">
                     
                             <p><i className="fa-solid fa-phone fs-4 text-white"></i> <span className='fs-5 mx-3 text-white'>01125664422</span></p> 
                             <p><i className="fa-solid fa-envelope fs-4 text-white"></i> <span className='fs-5 mx-3 text-white'>info@epsegypt.net</span></p>
                             <p><i className="fa-solid fa-envelope fs-4 text-white"></i> <span className='fs-5 mx-3 text-white'>eps.secu@gmail.com</span></p>

                      
                    </div>
                    <div className={`${styles.whiteLine}`}></div>
                    <div className='my-3 text-white fs-5'>
                    <i className="fa-regular fa-copyright"></i> EPS 2024. All Rights Reserved 
                    </div>
            </div>
        </div>
   </footer>
  </>
  )
}
