import React from 'react'
import { Link } from 'react-router-dom'
import styles from './OurWork.module.css'
import logo from '../MediaFolder/eps.png'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function OurWorks() {

  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  
  return (
   <>
          <section className='container my-5 py-5'>
          <div className={`${styles.bgCard} row mt-5 p-5`}>


          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj11" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'>Mirland Garage, Gesr Suez</p>
            <span className={`${styles.color2} fw-bold`}>See More ...</span>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}

            </Link> 
          </div>

          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj7" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> Agricultural banks 5 branches </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>
          
 
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj2" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> Al-Haram Arts Academy project </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>

          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj3" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> A project to develop the National Circus Artists’ Rooms in Agouza, the Artistic House for Folk and Performing Arts </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>

          <div className="col-md-4 my-5 text-center"  data-aos='fade-up'>
          <Link to="/proj1" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'>Balloon Theater Tank Project and Fire Pump Room, Artistic House for Folk and Performing Arts </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>
          
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj8" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> Firefighting project and fire tank at Fouh Hospital, General Authority for Health Insurance </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj6" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> The project to develop the National Circus in Agouza, inaugurated by the Minister</p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj9" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'>Academy of Arts Hospital, fire fighting works and the general site</p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>

          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj10" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'>Academy of Arts Student Affairs Building</p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj4" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> A project to create a fire tank, fire fighting, and smoke extraction for the central warehouses of the Technical House of the October Theater</p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>     
          <div className="col-md-4 my-5 text-center" data-aos='fade-up'>
          <Link to="/proj5" className={`${styles.linkPro}`}> 
            <img src={logo} alt="eps" className={`${styles.imgSizeProj}`}/>
            <p className='text-center mt-3'> Supply, installation and construction of a generator room at the Balloon Theater, the Artistic House for Popular and Performing Arts </p>
            {/* <button type="button" className={`${styles.GreyColor} btn btn-secondary`}>See More <span><i className="fa-solid fa-arrow-right mx-2"></i></span></button> */}
            <span className={`${styles.color2} fw-bold`}>See More ...</span>

            </Link> 
          </div>

          
        </div>
       </section>
   </>
  )
}
