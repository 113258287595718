import React from 'react'
import styles from './AboutUs.module.css'
import service1 from '../MediaFolder/service1.jpg'
import service2 from '../MediaFolder/service2.jpeg'
import service3 from '../MediaFolder/service3.jpg'
import service4 from '../MediaFolder/service4.jpg'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function AboutUs() {

    useEffect(() => {
        AOS.init({duration: 1500})
      
      }, [])

  return (
    <>
            <section className='my-5'>
        <div className={`container mt-5 pt-5`} >
        <div className={`${styles.bgCard} p-5 mt-5`}>

        <h2 className='text-center fs-3 fw-bolder'>
        What We Do
    
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
    
<p className='text-center my-5'> We are the authorized distributors of the major American and English brands where automatic alarms, automatic extinguishing devices and cameras. We have the highest work group and skill, whether experienced and efficient engineers or technicians to cover and handle any work at any time. We have a team for quick maintenance at any time, 24 hours. </p>
    <div className="row my-3">

 

        <div className="card col-md-6 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden}`}>
        <img src={service1} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Automatic fire alarm and extinguishing systems"/>
        </div>
        <div className={`${styles.divBG} card-body d-flex align-items-center justify-content-center`}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Automatic fire alarm and extinguishing systems and its maintenance</h2>
        </div>
        </div>

        
        <div className="card col-md-6 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden}`}>
        <img src={service2} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Electromechanical systems"/>
        </div>
        <div className={`${styles.divBG} card-body d-flex align-items-center justify-content-center`}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Electromechanical systems​</h2>
        </div>
        </div>


        <div className="card col-md-6 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden}`}>
        <img src={service4} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="Public supplies"/>
        </div>
        <div className={`${styles.divBG} card-body d-flex align-items-center justify-content-center`}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>Public supplies​</h2>
        </div>
        </div>

        <div className="card col-md-6 text-center border-0 my-1" data-aos="fade-up">
        <div className={`${styles.hidden}`}>
        <img src={service3} className={`${styles.imgHover} ${styles.imgSize} card-img-top `} alt="General contracting works and regular works"/>
        </div>
        <div className={`${styles.divBG} card-body d-flex align-items-center justify-content-center`}>
            <h2 className={`${styles.h2Hover} ${styles.color} fs-5`}>General contracting works and regular works​</h2>
        </div>
        </div>

        
   

    
    


    </div>
            

    
    </div>
    </div> 
        </section>

    
    
    </>
  )
}
