import React from 'react'
import styles from './ContactUs.module.css'
import { Link } from 'react-router-dom'



export default function ContactUs() {
  return (
   <>

 
           <div className='container mt-5'>
            <div className={`mt-5 p-5`}>
           
            <div className={`${styles.bgCard} row mt-5 `}>
            <div className="col-md-6 mt-5">
                <h2 className='text-center fs-3 fw-bolder'>
                        Social media
     
                    <div className="longline"></div>
                    <div className="shortline mb-2"></div>
                </h2>
                <div className={`${styles.social} d-flex justify-content-center my-3`}>
                        
                            <div className='my-3'>  <Link to='https://www.facebook.com' target={"_blank"}> <i className="fa-brands fa-facebook fs-1 mx-3"> </i> </Link>  </div> 
                            <div className='my-3'>  <Link to='https://instagram.com/' target={"_blank"}><i className="fa-brands fa-instagram fs-1 mx-3"></i></Link>  </div> 
                            <div className='my-3'>  <Link to='https://www.linkedin.com/company/' target={"_blank"}> <i className="fa-brands fa-linkedin fs-1 mx-3"></i> </Link></div> 
            
                </div>

            </div>
            <div className="col-md-6 mt-5 text-center">
                           <h2 className='text-center fs-3 fw-bolder ' >
                    Careers
                
                    <div className="longline"></div>
                    <div className="shortline mb-2"></div>
                </h2>
                <p className='text-center mt-3 fs-5 text-muted'>Join our team by apply to this mail with your job title  </p>
                <p className='text-center'> <a href="mailto:info@epsegypt.net" className={`${styles.anchorColor} fs-5 text-center`}> info@epsegypt.net </a></p>
            </div>

         
            <div className="col-md-12 mt-5">
                    <h2 className='text-center fs-3 fw-bolder '>
                        Get in touch
                        <div className="longline"></div>
                        <div className="shortline mb-2"></div>
                        </h2>


            <div className=" my-5"> 
            <form  >
            <input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`} type="text" placeholder="Name" aria-label="default input example" name="user_name"/>
            <input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`} type="email" placeholder="Email" aria-label="default input example" name="user_email"/>
            <input className={`${styles.formBorder} form-control mb-4 p-2 shadow-none`}  type="number" placeholder="Phone" aria-label="default input example" name="user_num"/>
            <textarea className={`${styles.formBorder} form-control shadow-none`} type="text" id="exampleFormControlTextarea1" placeholder="Message"rows="4" name='user_text'></textarea>
            <div className="text-center">
            <button type="submit" className={`${styles.btnEdit}  fw-bold mt-4`}> <i className="fa-solid fa-paper-plane"></i> Send Message</button>
            </div>
            </form>

            </div>

            </div>
            
                </div>
            </div>
        </div>
        <section className='container mt-5'>
        <div className="row">
            <h2 className='text-center'>Location</h2>
        <div className="col-md-12 text-center">
        <div className="longline"></div>
        <div className="shortline mb-5"></div>
        <iframe title='eps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.551827538199!2d31.215907400000003!3d30.049713000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584127ef1b2177%3A0xd22cde5e357ee44!2s35%20El-Fardous%2C%20Al%20Agouzah%2C%20Agouza%2C%20Giza%20Governorate%203753550!5e0!3m2!1sen!2seg!4v1714025982999!5m2!1sen!2seg" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


      

        </div>
        </div>
        </section>
        </>
  )
}
