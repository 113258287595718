import React from 'react'
import styles from './OurWork.module.css'
import proj1 from '../MediaFolder/projectsMedia/51.jpeg'
import proj2 from '../MediaFolder/projectsMedia/52.jpeg'
import proj3 from '../MediaFolder/projectsMedia/53.jpeg'
import proj4 from '../MediaFolder/projectsMedia/54.jpeg'
import proj5 from '../MediaFolder/projectsMedia/55.jpeg'
import proj6 from '../MediaFolder/projectsMedia/56.jpeg'
import proj7 from '../MediaFolder/projectsMedia/57.jpeg'
import proj8 from '../MediaFolder/projectsMedia/58.jpeg'
import proj9 from '../MediaFolder/projectsMedia/59.jpeg'


export default function Proj8() {
  return (
    <>
    <div className="container my-5">
     <div className="row ">
 
     <p className='text-center fw-bold my-5 fs-5 pt-5'> Firefighting project and fire tank at Fouh Hospital, General Authority for Health Insurance</p>
 
         <div className="col-md-4 my-1 text-center">
             <img src={proj1} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj2} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj3} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj4} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj5} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj6} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj7} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj8} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj9} alt="" className={`${styles.proImgSize}`}/>
         </div>
 
     </div>
    </div>
    </>
  )
}
