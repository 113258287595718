import React from 'react'
import styles from './OurWork.module.css'
import proj1 from '../MediaFolder/projectsMedia/11.jpeg'
import proj2 from '../MediaFolder/projectsMedia/12.jpeg'
import proj3 from '../MediaFolder/projectsMedia/13.jpeg'
import proj4 from '../MediaFolder/projectsMedia/14.jpeg'
import proj5 from '../MediaFolder/projectsMedia/15.jpeg'
import proj6 from '../MediaFolder/projectsMedia/16.jpeg'
import proj7 from '../MediaFolder/projectsMedia/17.jpeg'
import proj8 from '../MediaFolder/projectsMedia/18.jpeg'
import proj9 from '../MediaFolder/projectsMedia/19.jpeg'




export default function Proj4() {
  return (
    <>
    <div className="container my-5">
     <div className="row ">
 
     <p className='text-center fw-bold my-5 fs-5 pt-5'>A project to create a fire tank, fire fighting, and smoke extraction for the central warehouses of the Technical House of the October Theater  </p>
 
         <div className="col-md-4 my-1 text-center">
             <img src={proj1} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj2} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj3} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj4} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj5} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj6} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj7} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj8} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj9} alt="" className={`${styles.proImgSize}`}/>
         </div>
 
     </div>
    </div>
    </>
  )
}
