import React from 'react'
import { Link } from 'react-router-dom'
import styles from './NavBar.module.css'
import logo from '../MediaFolder/eps.png'
export default function NavVar() {
  return (
   <>
   
   <nav className={`${styles.navBgColor } navbar navbar-expand-lg navbar-light fixed-top `}>
  <div className="container px-3">
    <Link className="navbar-brand" to="home"> <img src={logo} alt="achegy" className={`${styles.logoSize }`}/> </Link> 
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

      <li className="nav-item">
          <Link className={`${styles.linkHover} px-3 fw-bold`} to="home">Home </Link>
        </li>
 
        <li className="nav-item">
          <Link className={`${styles.linkHover} px-3 fw-bold`} to="aboutUs">About Us </Link>
        </li>
   
        {/* <li className="nav-item dropdown">
          <Link className={`${styles.linkHover} px-3 fw-bold`} to="ourWorks">Our Works </Link>
          
        </li> */}

        <li class="nav-item dropdown">
          <Link class={`${styles.linkHover}  dropdown-toggle px-3 text-white fw-bold`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Our Works
          </Link>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item"to={'ourWorks2'}>Projects</Link></li>
            <li><Link class="dropdown-item" to={'ourWorks1'}>Selected Prjects</Link></li>
          </ul>
        </li>
 
        <li className="nav-item">
          <Link className={`${styles.linkHover} px-3 fw-bold`} to="contactUs">Contact Us </Link>
        </li>

        
      </ul>
      
    </div>
  </div>
</nav> 
   
   </>
  )
}
