import React from 'react'
import styles from './OurWork.module.css'
import proj1 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.28.15.jpeg'
import proj2 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.27.07 (1).jpeg'
import proj3 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.27.06 (1).jpeg'
import proj4 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.26.43.jpeg'
import proj5 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.26.42.jpeg'
import proj6 from '../MediaFolder/projectsMedia/WhatsApp Image 2024-04-25 at 13.26.42 (2).jpeg'


export default function Proj2() {
  return (
    <>
    <div className="container my-5">
     <div className="row">
 
     <p className='text-center fw-bold my-5 fs-5 pt-5'>Al-Haram Arts Academy project  </p>
 
         <div className="col-md-4 my-1 text-center">
             <img src={proj1} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj2} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj3} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj4} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj5} alt="" className={`${styles.proImgSize}`}/>
         </div>
         <div className="col-md-4 my-1 text-center">
             <img src={proj6} alt="" className={`${styles.proImgSize}`}/>
         </div>


 
 
     </div>
    </div>
    </>
  )
}
