import React from 'react'
import styles from './OurWork.module.css'
import allProj1 from '../MediaFolder/vipProj1.JPG'
import allProj2 from '../MediaFolder/vipProj2.JPG'
import allProj3 from '../MediaFolder/vipProj3.JPG'
import allProj4 from '../MediaFolder/vipProj4.JPG'
import allProj5 from '../MediaFolder/vipProj5.JPG'
import allProj6 from '../MediaFolder/vipProj6.JPG'
import allProj7 from '../MediaFolder/vipProj7.JPG'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'



export default function AllProjects() {

    useEffect(() => {
        AOS.init({duration: 1500})
      
      }, [])

  return (
   <>
   
   <section className='container mt-5'>
    <div className="row mt-5 justify-content-center">
        <h2 className='text-center fw-bold my-5 pt-5'> Projects History</h2>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj1} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>
        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj2} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj3} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj4} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1 " data-aos="zoom-in">
            <img src={allProj5} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj6} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
        <div className="col-md-5 my-2 text-center imgAnime mx-1" data-aos="zoom-in">
            <img src={allProj7} alt="eps"className={`${styles.AllProjImg}`} />
            <div className='redLayer text-white d-flex align-items-center justify-content-center'> <p className={`${styles.epsFontSize}`}>EPS</p> </div>

        </div>
    </div>

   </section>
   </>
  )
}
